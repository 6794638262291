export default [
  // {
  //   path: '/apps/calendar',
  //   name: 'apps-calendar',
  //   component: () => import('@/views/apps/calendar/Calendar.vue'),
  // },
  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/todo',
  //   name: 'apps-todo',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //   },
  // },
  // {
  //   path: '/apps/todo/:filter',
  //   name: 'apps-todo-filter',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/chat',
  //   name: 'apps-chat',
  //   component: () => import('@/views/apps/chat/Chat.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'chat-application',
  //   },
  // },

  {
    path: '/apps/home',
    name: 'apps-home',
    component: () => import('@/views/apps/home/home.vue'),
  },
  {
    path: '/apps/limit-setting/list',
    name: 'apps-limit-setting-list',
    component: () => import('@/views/apps/limit-setting/limit-list/List.vue'),
    meta: {
      pageTitle: 'Parameter & Limit Setting',
      breadcrumb: [
        // {
        //   text: 'Production',
        // },
        {
          text: 'Parameter & Limit Setting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/chatbot-setting/list',
    name: 'apps-chatbot-setting-list',
    component: () => import('@/views/apps/chatbot-setting/chatbot-list/List.vue'),
    meta: {
      pageTitle: 'Chatbot Setting',
      breadcrumb: [
        // {
        //   text: 'Production',
        // },
        {
          text: 'Chatbot Setting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/chatbot-log/list',
    name: 'apps-chatbot-log',
    component: () => import('@/views/apps/chatbot-setting/chatbot-log/List.vue'),
    meta: {
      pageTitle: 'Chatbot Log',
      breadcrumb: [
        // {
        //   text: 'Production',
        // },
        {
          text: 'Chatbot Log',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- Group Item CTR ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/dashboard/view',
    name: 'apps-dashboard-view',
    component: () => import('@/views/apps/dashboard/laporan-view/View.vue'),
  },
  {
    path: '/apps/fte/list',
    name: 'apps-fte-list',
    component: () => import('@/views/apps/fte/fte-list/List.vue'),
    meta: {
      pageTitle: 'FTE',
      breadcrumb: [
        {
          text: 'Dashboard',
        },
        {
          text: 'FTE',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/monthly-summary/view',
    name: 'apps-monthly-summary-view',
    component: () => import('@/views/apps/monthly-summary/laporan-view/View.vue'),
  },
  {
    path: '/apps/ct-monitoring/view',
    name: 'apps-ct-monitoring-view',
    component: () => import('@/views/apps/monitoring/ct-monitoring/laporan-view/View.vue'),
  },
  {
    path: '/apps/realtime-monitoring/view',
    name: 'apps-realtime-monitoring-view',
    component: () => import('@/views/apps/monitoring/realtime-monitoring/laporan-view/View.vue'),
  },
  {
    path: '/apps/best-ct-monitoring/view',
    name: 'apps-best-ct-monitoring-view',
    component: () => import('@/views/apps/monitoring/best-ct-monitoring/laporan-view/View.vue'),
  },
  {
    path: '/apps/plasticzing-monitoring/view',
    name: 'apps-plasticzing-monitoring-view',
    component: () => import('@/views/apps/monitoring/plasticzing-monitoring/laporan-view/View.vue'),
  },
  {
    path: '/apps/shot-data/view',
    name: 'apps-shot-data-view',
    component: () => import('@/views/apps/monitoring/shot-data/laporan-view/View.vue'),
  },
  {
    path: '/apps/monthly-result/view',
    name: 'apps-monthly-result-view',
    component: () => import('@/views/apps/monitoring/monthly-result/laporan-view/View.vue'),
  },
  {
    path: '/apps/grafik-mes/view',
    name: 'apps-grafik-mes-view',
    component: () => import('@/views/apps/monitoring/grafik-mes/laporan-view/View.vue'),
  },
  {
    path: '/apps/ctpp-progress/view',
    name: 'apps-ctpp-progress-view',
    component: () => import('@/views/apps/monitoring/ctpp-progress/laporan-view/View.vue'),
  },
  {
    path: '/apps/shiftly-result/view',
    name: 'apps-shiftly-result-view',
    component: () => import('@/views/apps/monitoring/shiftly-result/laporan-view/View.vue'),
  },
  {
    path: '/apps/production-overview/view',
    name: 'apps-production-overview-view',
    component: () => import('@/views/apps/production/production-overview/laporan-view/View.vue'),
  },
  {
    path: '/apps/issue/list',
    name: 'apps-issue-list',
    component: () => import('@/views/apps/production/issue/issue-list/List.vue'),
    meta: {
      pageTitle: 'Issues',
      breadcrumb: [
        {
          text: 'Production',
        },
        {
          text: 'Issues',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/weekly-data-prod/list',
    name: 'apps-weekly-data-prod-list',
    component: () => import('@/views/apps/production/weekly-data/weekly-list/List.vue'),
    meta: {
      pageTitle: 'Weekly Data',
      breadcrumb: [
        {
          text: 'Production',
        },
        {
          text: 'Weekly Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/weekly-data-prod/list/:mon',
    name: 'apps-weekly-data-prod-list',
    component: () => import('@/views/apps/production/weekly-data/weekly-list/List.vue'),
    meta: {
      pageTitle: 'Weekly Data',
      breadcrumb: [
        {
          text: 'Production',
        },
        {
          text: 'Weekly Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/weekly-data-mtn/list',
    name: 'apps-weekly-data-mtn-list',
    component: () => import('@/views/apps/maintenance/weekly-data/weekly-list/List.vue'),
    meta: {
      pageTitle: 'Weekly Data',
      breadcrumb: [
        {
          text: 'Maintenance',
        },
        {
          text: 'Weekly Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/weekly-data-qa/list',
    name: 'apps-weekly-data-qa-list',
    component: () => import('@/views/apps/qa/weekly-data/weekly-list/List.vue'),
    meta: {
      pageTitle: 'Weekly Data',
      breadcrumb: [
        {
          text: 'QA',
        },
        {
          text: 'Weekly Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/weekly-data-scm/list',
    name: 'apps-weekly-data-scm-list',
    component: () => import('@/views/apps/scm/weekly-data/weekly-list/List.vue'),
    meta: {
      pageTitle: 'Weekly Data',
      breadcrumb: [
        {
          text: 'SCM',
        },
        {
          text: 'Weekly Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/weekly-opr-main/list',
    name: 'apps-weekly-opr-main-list',
    component: () => import('@/views/apps/weekly-opr/main-kpi/main-list/List.vue'),
    meta: {
      pageTitle: 'Main KPI',
      breadcrumb: [
        {
          text: 'Weekly OPR',
        },
        {
          text: 'Main KPI',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/weekly-opr-target/list',
    name: 'apps-weekly-opr-target-list',
    component: () => import('@/views/apps/weekly-opr/target-kpi/target-list/List.vue'),
    meta: {
      pageTitle: 'Target KPI',
      breadcrumb: [
        {
          text: 'Weekly OPR',
        },
        {
          text: 'Target KPI',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/weekly-opr/list',
    name: 'apps-weekly-opr-list',
    component: () => import('@/views/apps/weekly-opr/laporan/weekly-list/List.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Weekly OPR',
        },
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/weekly-opr/oee/view/:mon/:week',
    name: 'apps-weekly-opr-oee-view',
    component: () => import('@/views/apps/weekly-opr/oee/View.vue'),
  },
  {
    path: '/apps/weekly-opr/reject/view/:mon/:week',
    name: 'apps-weekly-opr-reject-view',
    component: () => import('@/views/apps/weekly-opr/reject/View.vue'),
  },
  {
    path: '/apps/weekly-opr/ctpp/view/:mon/:week',
    name: 'apps-weekly-opr-ctpp-view',
    component: () => import('@/views/apps/weekly-opr/ctpp/View.vue'),
  },
  {
    path: '/apps/weekly-opr/cost/view/:mon/:week',
    name: 'apps-weekly-opr-cost-view',
    component: () => import('@/views/apps/weekly-opr/cost/View.vue'),
  },
  {
    path: '/apps/weekly-opr/sparepart/view/:mon/:week',
    name: 'apps-weekly-opr-sparepart-view',
    component: () => import('@/views/apps/weekly-opr/sparepart/View.vue'),
  },
  {
    path: '/apps/weekly-opr/unrecycle/view/:mon/:week',
    name: 'apps-weekly-opr-unrecycle-view',
    component: () => import('@/views/apps/weekly-opr/unrecycle/View.vue'),
  },
  {
    path: '/apps/weekly-opr/energy/view/:mon/:week',
    name: 'apps-weekly-opr-energy-view',
    component: () => import('@/views/apps/weekly-opr/energy/View.vue'),
  },
  {
    path: '/apps/chiller/list',
    name: 'apps-chiller-list',
    component: () => import('@/views/apps/monitoring/chiller/chiller-list/List.vue'),
    meta: {
      pageTitle: 'Chillers',
      breadcrumb: [
        {
          text: 'Monitoring',
        },
        {
          text: 'Chillers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/akses-lampu/list',
    name: 'apps-akses-lampu-list',
    component: () => import('@/views/apps/monitoring/akses-lampu/lampu-list/List.vue'),
    meta: {
      pageTitle: 'Akses Lampu',
      breadcrumb: [
        {
          text: 'Monitoring',
        },
        {
          text: 'Akses Lampu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/master-lampu/list',
    name: 'apps-master-lampu-list',
    component: () => import('@/views/apps/monitoring/master-lampu/lampu-list/List.vue'),
    meta: {
      pageTitle: 'Master Lampu',
      breadcrumb: [
        {
          text: 'Monitoring',
        },
        {
          text: 'Master Lampu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/ct-mixing/view',
    name: 'apps-ct-mixing-view',
    component: () => import('@/views/apps/production/ct-mixing/laporan-view/View.vue'),
  },

  {
    path: '/apps/energy-mold/view',
    name: 'apps-energy-mold-view',
    component: () => import('@/views/apps/mold/energy-mold/laporan-view/View.vue'),
  },
  {
    path: '/apps/mold-heatmap/view',
    name: 'apps-mold-heatmap-view',
    component: () => import('@/views/apps/mold/mold-heatmap/laporan-view/View.vue'),
  },
  {
    path: '/apps/scm-overview/view',
    name: 'apps-scm-overview-view',
    component: () => import('@/views/apps/scm/scm-overview/laporan-view/View.vue'),
  },
  {
    path: '/apps/daily-data/view',
    name: 'apps-daily-data-view',
    component: () => import('@/views/apps/scm/daily-data/laporan-view/View.vue'),
  },
  {
    path: '/apps/maintenance-overview/view',
    name: 'apps-maintenance-overview-view',
    component: () => import('@/views/apps/maintenance/maintenance-overview/laporan-view/View.vue'),
  },
  {
    path: '/apps/waiting-losses/view',
    name: 'apps-waiting-losses-view',
    component: () => import('@/views/apps/maintenance/waiting-losses/laporan-view/View.vue'),
  },
  {
    path: '/apps/maintenance-cost/view',
    name: 'apps-maintenance-cost-view',
    component: () => import('@/views/apps/maintenance/maintenance-cost/laporan-view/View.vue'),
  },
  {
    path: '/apps/maintenance-cost-rawdata/view',
    name: 'apps-maintenance-cost-rawdata',
    component: () => import('@/views/apps/maintenance/maintenance-cost-rawdata/laporan-view/View.vue'),
  },
  {
    path: '/apps/sparepart-value/view',
    name: 'apps-sparepart-value-view',
    component: () => import('@/views/apps/maintenance/sparepart-value/laporan-view/View.vue'),
  },
  {
    path: '/apps/sparepart-value-rawdata/view',
    name: 'apps-sparepart-value-rawdata',
    component: () => import('@/views/apps/maintenance/sparepart-value-rawdata/laporan-view/View.vue'),
  },
  {
    path: '/apps/losses-preview/view',
    name: 'apps-losses-preview-view',
    component: () => import('@/views/apps/maintenance/losses-preview/laporan-view/View.vue'),
  },
  {
    path: '/apps/group-item-ctr/list',
    name: 'apps-group-item-ctr-list',
    component: () => import('@/views/apps/ctr/group-item-ctr/group-list/List.vue'),
    meta: {
      pageTitle: 'Group Item',
      breadcrumb: [
        {
          text: 'CTR',
        },
        {
          text: 'Group Item',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/tag-setting/list',
    name: 'apps-tag-setting-list',
    component: () => import('@/views/apps/energy/tag-setting/tag-list/List.vue'),
    meta: {
      pageTitle: 'Tag Setting',
      breadcrumb: [
        {
          text: 'Energy',
        },
        {
          text: 'Tag Setting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/result-dynamic/view',
    name: 'apps-result-dynamic-view',
    component: () => import('@/views/apps/rawdata/result-dynamic/laporan-view/View.vue'),
  },
  {
    path: '/apps/recycle-unrecycle/view',
    name: 'apps-recycle-unrecycle-view',
    component: () => import('@/views/apps/rawdata/recycle-unrecycle/laporan-view/View.vue'),
  },
  {
    path: '/apps/powermeter/view',
    name: 'apps-powermeter-view',
    component: () => import('@/views/apps/rawdata/powermeter/laporan-view/View.vue'),
  },
  {
    path: '/apps/mixing/view',
    name: 'apps-mixing-view',
    component: () => import('@/views/apps/rawdata/mixing/laporan-view/View.vue'),
  },
  {
    path: '/apps/pelletizer/view',
    name: 'apps-pelletizer-view',
    component: () => import('@/views/apps/rawdata/pelletizer/laporan-view/View.vue'),
  },
  {
    path: '/apps/im-log-view',
    name: 'apps-im-log-view',
    component: () => import('@/views/apps/rawdata/im-log/laporan-view/View.vue'),
  },
  {
    path: '/apps/im-log/list',
    name: 'apps-im-log-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/apps/rawdata/im-log-new/im-log-list/List.vue'),
  },
  {
    path: '/apps/material-transport-view',
    name: 'apps-material-transport-view',
    component: () => import('@/views/apps/rawdata/material-transport/laporan-view/View.vue'),
  },
  {
    path: '/apps/tag-logger-view',
    name: 'apps-tag-logger-view',
    component: () => import('@/views/apps/rawdata/tag-logger/laporan-view/View.vue'),
  },
  {
    path: '/apps/master-produk-view',
    name: 'apps-master-produk-view',
    component: () => import('@/views/apps/rawdata/master-produk/laporan-view/View.vue'),
  },
  {
    path: '/apps/master-mold-raw-view',
    name: 'apps-master-mold-raw-view',
    component: () => import('@/views/apps/rawdata/master-mold/laporan-view/View.vue'),
  },
  {
    path: '/apps/reject-line-12-view',
    name: 'apps-reject-line-12-view',
    component: () => import('@/views/apps/rawdata/reject-line-12/laporan-view/View.vue'),
  },
  {
    path: '/apps/reject-line-35-view',
    name: 'apps-reject-line-35-view',
    component: () => import('@/views/apps/rawdata/reject-line-35/laporan-view/View.vue'),
  },
  {
    path: '/apps/reject-overflow-view',
    name: 'apps-reject-overflow-view',
    component: () => import('@/views/apps/rawdata/reject-overflow/laporan-view/View.vue'),
  },
  {
    path: '/apps/reject-type-view',
    name: 'apps-reject-type-view',
    component: () => import('@/views/apps/rawdata/reject-type/laporan-view/View.vue'),
  },
  {
    path: '/apps/master-mesin-view',
    name: 'apps-master-mesin-view',
    component: () => import('@/views/apps/rawdata/master-mesin/laporan-view/View.vue'),
  },
  {
    path: '/apps/master-kardus-view',
    name: 'apps-master-kardus-view',
    component: () => import('@/views/apps/rawdata/master-kardus/kardus-list/List.vue'),
    meta: {
      pageTitle: 'Master Kardus',
      breadcrumb: [
        {
          text: 'MES Rawdata',
        },
        {
          text: 'Master Kardus',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/qa-overview/view',
    name: 'apps-qa-overview-view',
    component: () => import('@/views/apps/qa/qa-overview/laporan-view/View.vue'),
  },
  {
    path: '/apps/qa-mixing/view',
    name: 'apps-qa-mixing-view',
    component: () => import('@/views/apps/qa/qa-mixing/laporan-view/View.vue'),
  },
  {
    path: '/apps/qa-mixing-azo/view',
    name: 'apps-qa-mixing-azo-view',
    component: () => import('@/views/apps/qa/qa-mixing-azo/laporan-view/View.vue'),
  },
  {
    path: '/apps/qa-pelletizer/view',
    name: 'apps-qa-pelletizer-view',
    component: () => import('@/views/apps/qa/qa-pelletizer/laporan-view/View.vue'),
  },
  {
    path: '/apps/qa-injection-moulding/view',
    name: 'apps-qa-injection-moulding-view',
    component: () => import('@/views/apps/qa/qa-injection-moulding/laporan-view/View.vue'),
  },
  {
    path: '/apps/qa-setcard/view',
    name: 'apps-qa-setcard-view',
    component: () => import('@/views/apps/qa/qa-setcard/laporan-view/View.vue'),
  },
  {
    path: '/apps/weighter-result-qa/view',
    name: 'apps-weighter-result-qa-view',
    component: () => import('@/views/apps/qa/weight-result-qa/laporan-view/View.vue'),
  },
  {
    path: '/apps/weighter-rowdata-qa/view',
    name: 'apps-weighter-rowdata-qa-view',
    component: () => import('@/views/apps/qa/weight-rowdata-qa/laporan-view/View.vue'),
  },
  {
    path: '/apps/fg-weighter-chart/view',
    name: 'apps-fg-weighter-chart-view',
    component: () => import('@/views/apps/qa/fg-weight-chart/laporan-view/View.vue'),
  },
  {
    path: '/apps/fg-std-summary/view',
    name: 'apps-fg-std-summary-view',
    component: () => import('@/views/apps/qa/fg-std-summary/laporan-view/View.vue'),
  },
  {
    path: '/apps/fg-std-summary-mold/view',
    name: 'apps-fg-std-summary-mold-view',
    component: () => import('@/views/apps/qa/fg-std-summary-mold/laporan-view/View.vue'),
  },
  {
    path: '/apps/fg-summary-overall/view',
    name: 'apps-fg-summary-overall-view',
    component: () => import('@/views/apps/qa/fg-summary-overall/laporan-view/View.vue'),
  },
  {
    path: '/apps/reject/view',
    name: 'apps-reject-view',
    component: () => import('@/views/apps/reject/reject/laporan-view/View.vue'),
  },
  {
    path: '/apps/reject-preview/view',
    name: 'apps-reject-preview-view',
    component: () => import('@/views/apps/reject_preview/reject/laporan-view/View.vue'),
  },
  {
    path: '/apps/item-ctr/list',
    name: 'apps-item-ctr-list',
    component: () => import('@/views/apps/ctr/item-ctr/item-list/List.vue'),
    meta: {
      pageTitle: 'Item',
      breadcrumb: [
        {
          text: 'CTR',
        },
        {
          text: 'Item',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- Resep CTR ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/resep-ctr/list',
    name: 'apps-resep-ctr-list',
    component: () => import('@/views/apps/ctr/resep-ctr/resep-list/List.vue'),
    meta: {
      pageTitle: 'Resep',
      breadcrumb: [
        {
          text: 'CTR',
        },
        {
          text: 'Resep',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/persen-resep-ctr/list',
    name: 'apps-persen-resep-ctr-list',
    component: () => import('@/views/apps/ctr/persen-resep-ctr/persen-list/List.vue'),
    meta: {
      pageTitle: 'Persentase Resep',
      breadcrumb: [
        {
          text: 'CTR',
        },
        {
          text: 'Persentase Resep',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/silo-ctr/list',
    name: 'apps-silo-ctr-list',
    component: () => import('@/views/apps/ctr/silo-ctr/silo-list/List.vue'),
    meta: {
      pageTitle: 'Silo',
      breadcrumb: [
        {
          text: 'CTR',
        },
        {
          text: 'Silo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stok-akhir-ctr/list',
    name: 'apps-stok-akhir-ctr-list',
    component: () => import('@/views/apps/ctr/stok-akhir-ctr/stok-list/List.vue'),
    meta: {
      pageTitle: 'Stok Akhir',
      breadcrumb: [
        {
          text: 'CTR',
        },
        {
          text: 'Stok Akhir',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/wip-ctr/list',
    name: 'apps-wip-ctr-list',
    component: () => import('@/views/apps/ctr/wip-ctr/wip-list/List.vue'),
    meta: {
      pageTitle: 'Data WIP',
      breadcrumb: [
        {
          text: 'CTR',
        },
        {
          text: 'Data WIP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/laporan-ctr/view',
    name: 'apps-laporan-ctr-view',
    component: () => import('@/views/apps/ctr/laporan-ctr/laporan-view/View.vue'),
  },
  // =====================================================================
  // ============================== OEE ==================================
  // =====================================================================
  {
    path: '/apps/split-downtime-oee/list',
    name: 'apps-split-downtime-oee-list',
    component: () => import('@/views/apps/oee/split-downtime/split-downtime-list/List.vue'),
    meta: {
      pageTitle: 'Split Downtime',
      breadcrumb: [
        {
          text: 'OEE',
        },
        {
          text: 'Split Downtime',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/split-downtime-oee-split/:id',
    name: 'apps-split-downtime-oee-split',
    component: () => import('@/views/apps/oee/split-downtime/split-downtime-split/List.vue'),
    meta: {
      pageTitle: 'Split Downtime Detail',
      breadcrumb: [
        {
          text: 'OEE',
        },
        {
          text: 'Split Downtime Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/laporan-oee/view',
    name: 'apps-laporan-oee-view',
    component: () => import('@/views/apps/oee/laporan/laporan-view/View.vue'),
  },

  {
    path: '/apps/powermeter-overview/view',
    name: 'apps-powermeter-overview-view',
    component: () => import('@/views/apps/energy/powermeter-overview/laporan-view/View.vue'),
  },

  {
    path: '/apps/energy-report/view',
    name: 'apps-energy-report-view',
    component: () => import('@/views/apps/energy/energy-report/laporan-view/View.vue'),
  },
  {
    path: '/apps/sld-overview/view',
    name: 'apps-sld-overview-view',
    component: () => import('@/views/apps/energy/sld-overview/laporan-view/View.vue'),
    meta: {
      pageTitle: 'SLD Overview',
      breadcrumb: [
        {
          text: 'Energy',
        },
        {
          text: 'SLD Overview',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/sld-overview-list/list',
    name: 'apps-sld-overview-list',
    component: () => import('@/views/apps/energy/sld-overview/sld-list/List.vue'),
    meta: {
      pageTitle: 'Device List',
      breadcrumb: [
        {
          text: 'Energy',
        },
        {
          text: 'SLD Overview',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/sld-overview-child-list/list/:id/:showname',
    name: 'apps-sld-overview-child-list',
    component: () => import('@/views/apps/energy/sld-overview/sld-child-list/List.vue'),
    meta: {
      pageTitle: 'Child Device List',
      breadcrumb: [
        {
          text: 'Energy',
        },
        {
          text: 'SLD Overview',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/weekly-simulator/list',
    name: 'apps-weekly-simulator-list',
    component: () => import('@/views/apps/mold/weekly-simulator/weekly-list/List.vue'),
    meta: {
      pageTitle: 'Weekly Mold Simulator',
      breadcrumb: [
        {
          text: 'Mold',
        },
        {
          text: 'Weekly Mold Simulator',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/mold-simulator/list',
    name: 'apps-mold-simulator-list',
    component: () => import('@/views/apps/mold/mold-simulator/mold-list/List.vue'),
    meta: {
      pageTitle: 'IM-Mold Simulator',
      breadcrumb: [
        {
          text: 'Mold',
        },
        {
          text: 'IM-Mold Simulator',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/energy-report-ytd/view',
    name: 'apps-energy-report-ytd-view',
    component: () => import('@/views/apps/energy/energy-report-ytd/laporan-view/View.vue'),
  },

  {
    path: '/apps/energy-report-tag/view',
    name: 'apps-energy-report-tag-view',
    component: () => import('@/views/apps/energy/energy-report-tag/laporan-view/View.vue'),
  },
  {
    path: '/apps/energy-report-tag-ytd/view',
    name: 'apps-energy-report-tag-ytd-view',
    component: () => import('@/views/apps/energy/energy-report-tag-ytd/laporan-view/View.vue'),
  },
  {
    path: '/apps/device-list/view',
    name: 'apps-device-list-view',
    component: () => import('@/views/apps/energy/device-list/laporan-view/View.vue'),
  },
  {
    path: '/apps/energy-trend/view',
    name: 'apps-energy-trend-view',
    component: () => import('@/views/apps/energy/energy-trend/laporan-view/View.vue'),
  },
  {
    path: '/apps/daily-record/view',
    name: 'apps-daily-record-view',
    component: () => import('@/views/apps/energy/daily-record/laporan-view/View.vue'),
  },
  {
    path: '/apps/monthly-record/view',
    name: 'apps-monthly-record-view',
    component: () => import('@/views/apps/energy/monthly-record/laporan-view/View.vue'),
  },
  {
    path: '/apps/yearly-record/view',
    name: 'apps-yearly-record-view',
    component: () => import('@/views/apps/energy/yearly-record/laporan-view/View.vue'),
  },
  {
    path: '/apps/energy-raw-data/view',
    name: 'apps-energy-raw-data-view',
    component: () => import('@/views/apps/energy/energy-raw-data/laporan-view/View.vue'),
  },
  {
    path: '/apps/energy-table/view',
    name: 'apps-energy-table-view',
    component: () => import('@/views/apps/energy/energy-table/laporan-view/View.vue'),
  },
  {
    path: '/apps/alarm-data/view',
    name: 'apps-alarm-data-view',
    component: () => import('@/views/apps/energy/alarm-data/laporan-view/View.vue'),
  },
  {
    path: '/apps/improvement-monitor/view',
    name: 'apps-improvement-monitor-view',
    component: () => import('@/views/apps/energy/improvement-monitor/laporan-view/View.vue'),
  },
  {
    path: '/apps/target-energy/view',
    name: 'apps-target-energy-list',
    component: () => import('@/views/apps/energy/target-energy/target-list/List.vue'),
    meta: {
      pageTitle: 'Target Energy',
      breadcrumb: [
        {
          text: 'Improvement Monitor',
        },
        {
          text: 'Target Energy',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/energy-before-mes/view',
    name: 'apps-energy-before-mes-list',
    component: () => import('@/views/apps/energy/energy-before-mes/before-list/List.vue'),
    meta: {
      pageTitle: 'Energy Before MES',
      breadcrumb: [
        {
          text: 'Improvement Monitor',
        },
        {
          text: 'Energy Before MES',
          active: true,
        },
      ],
    },
  },
  // =====================================================================
  // ============================== OEE ==================================
  // =====================================================================
  {
    path: '/apps/mold-ctpp/list',
    name: 'apps-mold-ctpp-list',
    component: () => import('@/views/apps/ctpp/mold-ctpp/mold-list/List.vue'),
    meta: {
      pageTitle: 'Master Mold',
      breadcrumb: [
        {
          text: 'CTPP',
        },
        {
          text: 'Master Mold',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/planning-ctpp/list',
    name: 'apps-planning-ctpp-list',
    component: () => import('@/views/apps/ctpp/planning-ctpp/planning-list/List.vue'),
    meta: {
      pageTitle: 'Data Planning Fitting',
      breadcrumb: [
        {
          text: 'CTPP',
        },
        {
          text: 'Data Planning Fitting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/planning-ctpp-pipa/list',
    name: 'apps-planning-ctpp-pipa-list',
    component: () => import('@/views/apps/ctpp/planning-ctpp-pipa/planning-list/List.vue'),
    meta: {
      pageTitle: 'Data Planning Pipa',
      breadcrumb: [
        {
          text: 'CTPP',
        },
        {
          text: 'Data Planning Pipa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/laporan-ctpp/view',
    name: 'apps-laporan-ctpp-view',
    component: () => import('@/views/apps/ctpp/laporan/laporan-view/View.vue'),
  },
  {
    path: '/apps/log-report-detail/list',
    name: 'apps-log-report-detail-list',
    // component: () => import('@/views/apps/ctpp/laporan/laporan-view/View.vue'),
    component: () => import('@/views/apps/log/log-detail/log-detail-list/List.vue'),
    meta: {
      pageTitle: 'Report Detail',
      breadcrumb: [
        {
          text: 'Log Report',
        },
        {
          text: 'Report Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/log-report-summary/list',
    name: 'apps-log-report-summary-list',
    // component: () => import('@/views/apps/ctpp/laporan/laporan-view/View.vue'),
    component: () => import('@/views/apps/log/log-summary/log-summary-list/List.vue'),
    meta: {
      pageTitle: 'Report Summary',
      breadcrumb: [
        {
          text: 'Log Report',
        },
        {
          text: 'Report Summary',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/log-report-date/list',
    name: 'apps-log-report-date-list',
    // component: () => import('@/views/apps/ctpp/laporan/laporan-view/View.vue'),
    component: () => import('@/views/apps/log/log-date/log-date-list/List.vue'),
    meta: {
      pageTitle: 'Report By Date',
      breadcrumb: [
        {
          text: 'Log Report',
        },
        {
          text: 'Report By Date',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/hak-akses/list',
    name: 'apps-hak-akses-list',
    component: () => import('@/views/apps/menu-setting/hak-akses/akses-list/List.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Hak Akses',
        },
        {
          text: 'Set Hak Akses',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/menu-setting/list',
    name: 'apps-menu-setting-list',
    component: () => import('@/views/apps/menu-setting/menu-setting/menu-list/List.vue'),
    meta: {
      pageTitle: 'Menu',
      breadcrumb: [
        {
          text: 'Setting Menu',
        },
        {
          text: 'Periode Akses',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/users/list',
  //   name: 'apps-users-list',
  //   component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  // },
  // {
  //   path: '/apps/users/view/:id',
  //   name: 'apps-users-view',
  //   component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  // },
  // {
  //   path: '/apps/users/edit/:id',
  //   name: 'apps-users-edit',
  //   component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  // },

  // // Invoice
  // {
  //   path: '/apps/invoice/list',
  //   name: 'apps-invoice-list',
  //   component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  // },
  // {
  //   path: '/apps/invoice/preview/:id',
  //   name: 'apps-invoice-preview',
  //   component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  // },
  // {
  //   path: '/apps/invoice/add/',
  //   name: 'apps-invoice-add',
  //   component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  // },
  // {
  //   path: '/apps/invoice/edit/:id',
  //   name: 'apps-invoice-edit',
  //   component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  // },
]
